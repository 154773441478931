<template>
	<div>
		<v-btn
			@click="sendMail()"
		>
			enviar
		</v-btn>
		<s-crud
			title="Tareas"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
			ref="gridtask"
			@rowSelected="rowSelected($event)"
			height="750"
		>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col v-if="filter.IsAdmin == 1">
							<s-select
								label="Responsable"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="filter.PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="filter.TskPriority"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="filter.TskStatus"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template scope="props">
				<v-container>
					<v-row justify="center">
						<v-col>
							<s-select
								label="Asignado a"
								:items="workerstic"
								autocomplete
								clearable
								v-model="props.item.PrsID"
								item-text="WorkerName"
								item-value="PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="props.item.TskPriority"
							></s-select-definition>
						</v-col>
						<v-col>
							<s-text
								label="Tarea"
								v-model="props.item.TskTitle"
							></s-text>
						</v-col>
						<v-col>
							<s-textarea
								label="Descripción"
								v-model="props.item.TskDescription"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:options>
				<v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="pausedTask()"> 
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 0"> far fa-pause-circle</v-icon>
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 1"> far fa-play-circle</v-icon>
						</v-btn>
					</template>
					<span v-if="selected.TskPaused == 0">Pausar</span>
					<span v-if="selected.TskPaused == 1">Iniciar</span>
				</v-tooltip>

				<v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="transferTask()"> <v-icon style="font-size:18px;"> fas fa-random</v-icon></v-btn>
					</template>
					<span>Transferir</span>
				</v-tooltip>
			</template>

			<template v-slot:Action="{ row }">
				<v-btn
					v-if="row.TskStatus == 1"
					x-small
					color="success"
					@click="clickActionTask(row)"
				>
					{{'Iniciar'}}
				</v-btn>
				<v-btn
					v-if="row.TskStatus == 2"
					x-small
					color="error"
					@click="clickActionTask(row)"
				>
					{{'Terminar'}}
				</v-btn>
			</template>

			<template v-slot:TskStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TskStatusColor"
				>
					{{ row.TskStatusName }}
				</v-chip>
			</template>

			<template v-slot:TskPriority="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TskPriorityColor"
				>
					{{ row.TskPriorityName }}
				</v-chip>
			</template>
		</s-crud>

		<v-dialog
			v-model="dialogTransfer"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-select
								label="Transferir a"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="datatransfer.PrsIDTraslate"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="datatransfer.TskReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendTransfer()"
							>
								{{"Transferir"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelTransferTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogPaused"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="dataPaused.TskReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendPaused()"
							>
								{{selected.TskPaused == 0 ? "Pausar" : "Reanudar"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelPausedTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
	import sTextarea from '../../../components/Utils/sTextarea.vue';

	export default {
  components: { sTextarea },
		data() {
			return {
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				selected : {},
				workerstic: [],
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0
				},
				config: {
					model: {
						TskID: "ID",
						Action: "Action",
						TskStatus: "integer",
						TskPriority: "integer"
					},
					service: _sTaskService,
					headers: [
						{text: "ID", value: "TskID"},
						{text: "Acción", value: "Action"},
						{text: "Tarea", value: "TskTitle"},
						{text: "Descripción", value: "TskDescription"},
						{text: "Prioridad", value: "TskPriority"},
						{text: "Abierto", value: "TskDateName"},
						{text: "Asignado a", value: "WorkerName"},
						{text: "F. Ini", value: "TskDateBeginName"},
						{text: "F. Fin", value: "TskDateEndName"},
						{text: "Estado", value: "TskStatus"},
					]
				},
				
			}
		},
		watch : {
			filter(){
				console.log(this.filter)
			}
		},
		methods: {
			sendMail()
			{
				_sTaskService
				.sendmail(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Enviado correctamente", "success");
						return;
					}
				})
			},

			initialize()
			{
				this.getTicWorkers();
			},

			save(item) {
				item.save();
			},

			getTicWorkers()
			{
				let obj = {
					AreID: 32
				}
				_sTaskService
				.ticworkers(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						console.log('tag', resp.data)
						this.workerstic = resp.data;
					}
				})
			},

			clickActionTask(item)
			{
				if(this.selected.TskPaused == 1)
				{
					this.$fun.alert("La tarea se encuentra pausada", "info");
					return;
				}

				let message = ''
				if (item.TskStatus == 1)
				{
					message = '¿Seguro de iniciar?'
					item.TskStatus = 100;
				}
				else
				{
					message = '¿Seguro de finalizar?'
					item.TskStatus = 101;
				}

				this.$fun.alert(message, "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Actualizado correctamente", "success");
								this.$refs.gridtask.refresh();
							}
						})
					}
				})
				
			},

			transferTask()
			{
				console.log('estado ', this.selected.TskStatus);
				if(this.selected.TskStatus != 1 && this.selected.TskStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				this.dialogTransfer = true;
			},

			pausedTask()
			{
				if(this.selected.TskStatus != 1 && this.selected.TskStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				if(this.selected.TskPaused == 1)
				{
					this.$fun.alert("¿Seguro de reanudar tarea?", "question")
					.then(r => {
						if(r.value)
						{
							this.selected.TskStatus = 104;
							this.selected.UsrUpdateID = this.$fun.getUserID();
							_sTaskService
							.save(this.selected, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
									this.$fun.alert("Reanudada correctamente", "success");
									this.$refs.gridtask.refresh();
									this.dialogPaused = false;
									return;
								}
							})
						}
					})
					return;
				}
				if(this.selected.TskPaused == 0)
				{
					this.dialogPaused = true;
				}
				
			},

			cancelPausedTask()
			{
				this.dialogPaused = false;
			},

			sendPaused()
			{

				this.selected.TskStatus = 103;
				this.selected.UsrUpdateID = this.$fun.getUserID();
				this.selected.TskReasonTraslate = this.dataPaused.TskReasonTraslate;
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogPaused = false;
								return;
							}
						})
					}
				})
			},

			cancelTransferTask()
			{
				this.dialogTransfer = false;
			},

			sendTransfer()
			{
				if(this.selected.PrsID == this.datatransfer.PrsIDTraslate)
				{
					this.$fun.alert("No se puede trasladar a la misma persona", "info");
					return;
				}
				this.$fun.alert("¿Seguro de transferir?", "question")
				.then(r => {
					if(r.value)
					{
						this.selected.PrsIDTraslate = this.datatransfer.PrsIDTraslate;
						this.selected.TskReasonTraslate = this.datatransfer.TskReasonTraslate;
						this.selected.UsrUpdateID = this.$fun.getUserID();
						this.selected.TskStatus = 102
						
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Transferido correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogTransfer = false;
							}
						})
					}
				})
				
			},

			rowSelected(items)
			{
				if(items.length > 0)
				{
					this.selected = items[0];
				}
			}
		},

		created () {
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserID() 
					
			console.log( this.filter.PrsID); 
		}
	}
</script>

<style scoped>

</style>